//
// Utility classes
// -------------------------

// Spacing classes

/* Margin */
.m-a-0 { margin:        0 !important; }
.m-t-0 { margin-top:    0 !important; }
.m-r-0 { margin-right:  0 !important; }
.m-b-0 { margin-bottom: 0 !important; }
.m-l-0 { margin-left:   0 !important; }
.m-x-0 { margin-right:  0 !important; margin-left:   0 !important; }
.m-y-0 { margin-top:    0 !important; margin-bottom: 0 !important; }

.m-a { margin:        $spacer !important; }
.m-t { margin-top:    $spacer-y !important; }
.m-r { margin-right:  $spacer-x !important; }
.m-b { margin-bottom: $spacer-y !important; }
.m-l { margin-left:   $spacer-x !important; }
.m-x { margin-right:  $spacer-x !important; margin-left: $spacer-x !important; }
.m-y { margin-top:    $spacer-y !important; margin-bottom: $spacer-y !important; }
.m-x-auto { margin-right: auto !important; margin-left: auto !important; }

.m-a-xs { margin:        ($spacer * .25) !important; }
.m-t-xs { margin-top:    ($spacer-y * .25) !important; }
.m-r-xs { margin-right:  ($spacer-y * .25) !important; }
.m-b-xs { margin-bottom: ($spacer-y * .25) !important; }
.m-l-xs { margin-left:   ($spacer-y * .25) !important; }
.m-x-xs { margin-right:  ($spacer-x * .25) !important; margin-left:   ($spacer-x * .25) !important; }
.m-y-xs { margin-top:    ($spacer-y * .25) !important; margin-bottom: ($spacer-y * .25) !important; }

.m-a-sm { margin:        ($spacer * .5) !important; }
.m-t-sm { margin-top:    ($spacer-y * .5) !important; }
.m-r-sm { margin-right:  ($spacer-y * .5) !important; }
.m-b-sm { margin-bottom: ($spacer-y * .5) !important; }
.m-l-sm { margin-left:   ($spacer-y * .5) !important; }
.m-x-sm { margin-right:  ($spacer-x * .5) !important; margin-left:   ($spacer-x * .5) !important; }
.m-y-sm { margin-top:    ($spacer-y * .5) !important; margin-bottom: ($spacer-y * .5) !important; }

.m-a-md { margin:        ($spacer * 1.5) !important; }
.m-t-md { margin-top:    ($spacer-y * 1.5) !important; }
.m-r-md { margin-right:  ($spacer-y * 1.5) !important; }
.m-b-md { margin-bottom: ($spacer-y * 1.5) !important; }
.m-l-md { margin-left:   ($spacer-y * 1.5) !important; }
.m-x-md { margin-right:  ($spacer-x * 1.5) !important; margin-left:   ($spacer-x * 1.5) !important; }
.m-y-md { margin-top:    ($spacer-y * 1.5) !important; margin-bottom: ($spacer-y * 1.5) !important; }

.m-a-lg { margin:        ($spacer * 3) !important; }
.m-t-lg { margin-top:    ($spacer-y * 3) !important; }
.m-r-lg { margin-right:  ($spacer-y * 3) !important; }
.m-b-lg { margin-bottom: ($spacer-y * 3) !important; }
.m-l-lg { margin-left:   ($spacer-y * 3) !important; }
.m-x-lg { margin-right:  ($spacer-x * 3) !important; margin-left:   ($spacer-x * 3) !important; }
.m-y-lg { margin-top:    ($spacer-y * 3) !important; margin-bottom: ($spacer-y * 3) !important; }


/* Padding */
.p-a-0 { padding:        0 !important; }
.p-t-0 { padding-top:    0 !important; }
.p-r-0 { padding-right:  0 !important; }
.p-b-0 { padding-bottom: 0 !important; }
.p-l-0 { padding-left:   0 !important; }
.p-x-0 { padding-left:   0 !important; padding-right: 0 !important; }
.p-y-0 { padding-top:    0 !important; padding-bottom: 0 !important; }

.p-a { padding:        $spacer !important; }
.p-t { padding-top:    $spacer-y !important; }
.p-r { padding-right:  $spacer-x !important; }
.p-b { padding-bottom: $spacer-y !important; }
.p-l { padding-left:   $spacer-x !important; }
.p-x { padding-right:  $spacer-x !important; padding-left:   $spacer-x !important; }
.p-y { padding-top:    $spacer-y !important; padding-bottom: $spacer-y !important; }

.p-a-md { padding:        ($spacer * 1.5) !important; }
.p-t-md { padding-top:    ($spacer-y * 1.5) !important; }
.p-r-md { padding-right:  ($spacer-y * 1.5) !important; }
.p-b-md { padding-bottom: ($spacer-y * 1.5) !important; }
.p-l-md { padding-left:   ($spacer-y * 1.5) !important; }
.p-x-md { padding-right:  ($spacer-x * 1.5) !important; padding-left:   ($spacer-x * 1.5) !important; }
.p-y-md { padding-top:    ($spacer-y * 1.5) !important; padding-bottom: ($spacer-y * 1.5) !important; }

.p-a-lg { padding:        ($spacer * 3) !important; }
.p-t-lg { padding-top:    ($spacer-y * 3) !important; }
.p-r-lg { padding-right:  ($spacer-y * 3) !important; }
.p-b-lg { padding-bottom: ($spacer-y * 3) !important; }
.p-l-lg { padding-left:   ($spacer-y * 3) !important; }
.p-x-lg { padding-right:  ($spacer-x * 3) !important; padding-left:   ($spacer-x * 3) !important; }
.p-y-lg { padding-top:    ($spacer-y * 3) !important; padding-bottom: ($spacer-y * 3) !important; }

.p-a-xl { padding:        ($spacer * 5) !important; }
.p-t-xl { padding-top:    ($spacer-y * 5) !important; }
.p-r-xl { padding-right:  ($spacer-y * 5) !important; }
.p-b-xl { padding-bottom: ($spacer-y * 5) !important; }
.p-l-xl { padding-left:   ($spacer-y * 5) !important; }
.p-x-xl { padding-right:  ($spacer-x * 5) !important; padding-left:   ($spacer-x * 5) !important; }
.p-y-xl { padding-top:    ($spacer-y * 5) !important; padding-bottom: ($spacer-y * 5) !important; }

/* Positioning */
.pos-r { position: relative !important; }
.pos-a { position: absolute !important; }
.pos-f { position: fixed !important; }

.pos-f-t {
	position: fixed;
	top: 0;
	right: 0;
	left: 0;
	z-index: 1030;
}

/* Shift element to top */
// Used on base_pages_profile.html
.shift-top {
	margin-top: -75px;
}

.shift-top-sm {
	@media screen and (min-width: $screen-sm-min) {
		margin-top: -75px;
	}
}

.shift-top-md {
	@media screen and (min-width: $screen-md-min) {
		margin-top: -75px;
	}
}

.shift-top-lg {
	@media screen and (min-width: $screen-lg-min) {
		margin-top: -75px;
	}
}

/* Sizing */
.w-5 { width: 5%; }
.w-10 { width: 10%; }
.w-15 { width: 15%; }
.w-20 { width: 20%; }
.w-25 { width: 25%; }
.w-30 { width: 30%; }
.w-35 { width: 35%; }
.w-40 { width: 40%; }
.w-45 { width: 45%; }
.w-50 { width: 50%; }
.w-55 { width: 55%; }
.w-60 { width: 60%; }
.w-65 { width: 65%; }
.w-70 { width: 70%; }
.w-75 { width: 75%; }
.w-80 { width: 80%; }
.w-85 { width: 85%; }
.w-90 { width: 90%; }
.w-95 { width: 95%; }
.w-100 { width: 100%; }

/* Vertical aligning */
// Used on rows and affects child "col-"'s
.vcenter {
	display: flex;
	align-items: center;

	&-sm {
		@media screen and (min-width: $screen-sm-min) {
			display: flex;
			align-items: center;
		}
	}

	&-md {
		@media screen and (min-width: $screen-md-min) {
			display: flex;
			align-items: center;
		}
	}

	&-lg {
		@media screen and (min-width: $screen-lg-min) {
			display: flex;
			align-items: center;
		}
	}
}

/* Responsive text-aligning */
.text-xs-left   { text-align: left; }
.text-xs-right  { text-align: right; }
.text-xs-center { text-align: center; }

@media screen and (min-width: $screen-sm-min) {
	.text-sm-left   { text-align: left; }
	.text-sm-right  { text-align: right; }
	.text-sm-center { text-align: center; }
}

@media screen and (min-width: $screen-md-min) {
	.text-md-left   { text-align: left; }
	.text-md-right  { text-align: right; }
	.text-md-center { text-align: center; }
}

@media screen and (min-width: $screen-lg-min) {
	.text-lg-left   { text-align: left; }
	.text-lg-right  { text-align: right; }
	.text-lg-center { text-align: center; }
}

/* Borders */
.b-a { border: 1px solid $gray-light-o; }
.b-t { border-top: 1px solid $gray-light-o; }
.b-r { border-right: 1px solid $gray-light-o; }
.b-b { border-bottom: 1px solid $gray-light-o; }
.b-l { border-left: 1px solid $gray-light-o; }

/* Shadow */
.shadow-0 { @include shadow(0); }
.shadow-1 { @include shadow(1); }
.shadow-2 { @include shadow(2); }
.shadow-3 { @include shadow(3); }
.shadow-4 { @include shadow(4); }
.shadow-5 { @include shadow(5); }
.shadow-6 { @include shadow(6); }

/* Hover shadow effect */
.hover-shadow-1 {
	transition: all 0.2s ease-in-out;

	&:hover {
		@include shadow(1);
	}
}

.hover-shadow-2 {
	transition: all 0.2s ease-in-out;

	&:hover {
		@include shadow(2);
	}
}

.hover-shadow-3 {
	transition: all 0.2s ease-in-out;

	&:hover {
		@include shadow(3);
	}
}

.hover-shadow-4 {
	transition: all 0.2s ease-in-out;

	&:hover {
		@include shadow(4);
	}
}

.hover-shadow-5 {
	transition: all 0.2s ease-in-out;

	&:hover {
		@include shadow(4);
	}
}

/* Icons sizing */
.fa-1-5x { font-size: 1.5em; }
.fa-2x { font-size: 2em; }
.fa-3x { font-size: 3em; }
.fa-4x { font-size: 4em; }
.fa-5x { font-size: 5em; }
.fa-6x { font-size: 6em; }
.fa-7x { font-size: 7em; }
.fa-8x { font-size: 8em; }
.fa-9x { font-size: 9em; }
.fa-10x {	font-size: 10em; }
